import React, { SyntheticEvent, useCallback } from 'react';
import { useStyles } from './FiltersMultipleAutocomplete.styles';
import { useDispatch } from 'react-redux';
import { setClientSubProjectFilters } from '../../redux/actions';
import { Field, FormikProps } from 'formik';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';

interface Props {
  name: string;
  value: string[];
  handleChange: (fieldName: string, fieldValue: string[]) => void;
  options: string[];
  label: string;
  placeholder: string;
}
export const FiltersMultipleAutocomplete: React.FC<Props> = ({
  name,
  value,
  handleChange,
  options,
  label,
  placeholder,
}: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const handleChangeFilter = useCallback((name: string, newValue: string[]) => {
    handleChange(name, newValue);
    dispatch(setClientSubProjectFilters({ [name]: newValue }));
  }, []);
  return (
    <Field
      name={name}
      multiple
      component={Autocomplete}
      value={value}
      onChange={(_: SyntheticEvent, fieldValue: string[]) => handleChangeFilter(name, fieldValue)}
      options={options}
      disableClearable
      label={label}
      limitTags={3}
      renderInput={(params: FormikProps<TextFieldProps>) => (
        <TextField
          {...params}
          className={styles.textField}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};
