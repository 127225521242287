export enum EvaluationInfoViewTexts {
  NoUpcomingEvaluations = "You don't have upcoming evaluations for now",
  NoOngoingEvaluations = "You don't have ongoing evaluations for now",
  NoOverdueEvaluations = "You don't have overdue evaluations for now",
  NoPendingToStartEvaluations = "You don't have any pending evaluations to start for now",
  NoPendingToCloseEvaluations = "You don't have any pending evaluations to close for now",
  NoPeers = "You don't have any peer reviews for now",
  NoFeedbacksFromBothSides = 'In order to view this page, reviews from both sides must be filled out',
  NoFeedbacksYet = 'No feedbacks yet',
}
