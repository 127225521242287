import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { ImageUrls } from '@shared/enums/ImageUrls';
import {
  setClientContactData,
  setClientSubProjectData,
  setClientSubProjectNetworkError,
  setClientSubProjectFilters,
} from './actions';

import { State } from './types';
import { ClientFiltersInitialValues } from '../components/constants/ClientContactFromInitialValues';
import { FeedbackGiver } from '../enums/ContactFilterOptions';

const initialState: State = {
  clientContactData: { data: null, state: DataState.Pending },
  clientSubProjectData: { data: null, state: DataState.Pending },
  clientSubProjectNetworkError: null,
  editClientDataState: DataState.Idle,
  uniqueFilterOptions: {
    status: [],
    typeOfClient: [],
    clientTitle: [],
    includeClosedWorkstreams: false,
    contactOwner: [],
    contactLocation: [],
    locationArea: [],
    feedbackGiver: Object.values(FeedbackGiver),
  },
  clientFilters: ClientFiltersInitialValues,
};

export const slice = createSlice({
  name: 'clientContactPage',
  initialState,
  reducers: {
    setClientContactDataLoading(state) {
      state.clientContactData = { data: null, state: DataState.Pending };
    },
    setClientSubProjectDataLoading(state) {
      state.clientSubProjectData = { data: null, state: DataState.Pending };
      state.editClientDataState = DataState.Idle;
    },
    setEditClientDataState(state, { payload }) {
      state.editClientDataState = payload;
    },
    addClientContactStore(state, { payload }) {
      if (!state.clientSubProjectData.data) {
        return;
      }
      const title = payload.title !== 'Other' ? payload.title : payload.customTitle;
      state.clientSubProjectData.data = state.clientSubProjectData.data.map((subProject) => {
        if (subProject.name === payload.accountName) {
          subProject.contacts.push({
            ...payload,
            title,
            imageUrl: ImageUrls.Client,
          });
        }
        return subProject;
      });
    },
    editClientContactStore(state, { payload }) {
      if (!state.clientSubProjectData.data) {
        return;
      }
      const title = payload.title !== 'Other' ? payload.title : payload.customTitle;
      state.clientSubProjectData.data = state.clientSubProjectData.data.map((subProject) => {
        if (subProject.id === payload.workstream) {
          subProject.contacts = subProject.contacts.map((contact) => {
            if (contact.id === payload.id) {
              contact = {
                ...payload,
                title,
                imageUrl: ImageUrls.Client,
              };
            }
            return contact;
          });
        }
        return subProject;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setClientContactData, (state, { payload }) => {
      state.clientContactData = payload;
    });
    builder.addCase(setClientSubProjectData, (state, { payload }) => {
      state.clientSubProjectData = payload;
      state.uniqueFilterOptions = (() => {
        const status: string[] = [];
        const typeOfClient: string[] = [];
        const clientTitle: string[] = [];
        const contactOwner: string[] = [];
        const contactLocation: string[] = [];
        const locationArea: string[] = [];

        if (payload.data) {
          payload.data.forEach((client) => {
            client.contacts.forEach((contact) => {
              if (contact.rmContactStatus && !status.includes(contact.rmContactStatus)) {
                status.push(contact.rmContactStatus);
              }

              if (contact.rmTypeOfContact && !typeOfClient.includes(contact.rmTypeOfContact)) {
                typeOfClient.push(contact.rmTypeOfContact);
              }

              if (contact.contactOwner && !contactOwner.includes(contact.contactOwner)) {
                contactOwner.push(contact.contactOwner);
              }

              if (contact.title && !clientTitle.includes(contact.title)) {
                clientTitle.push(contact.title);
              }

              if (
                contact.location &&
                contact.location.toLocaleLowerCase() !== 'na' &&
                !contactLocation.includes(contact.location)
              ) {
                contactLocation.push(contact.location);
              }

              if (
                contact.locationArea &&
                contact.location.toLocaleLowerCase() !== 'na' &&
                !locationArea.includes(contact.locationArea)
              ) {
                locationArea.push(contact.locationArea);
              }
            });
          });
        }

        return {
          status,
          typeOfClient,
          contactOwner,
          includeClosedWorkstreams: state.uniqueFilterOptions.includeClosedWorkstreams,
          clientTitle,
          contactLocation,
          locationArea,
          feedbackGiver: state.uniqueFilterOptions.feedbackGiver,
        };
      })();
    });

    builder.addCase(setClientSubProjectFilters, (state, { payload }) => {
      state.clientFilters = { ...state.clientFilters, ...payload };
    });

    builder.addCase(setClientSubProjectNetworkError, (state, { payload }) => {
      state.clientSubProjectNetworkError = payload;
    });
  },
});

export const {
  setClientContactDataLoading,
  setClientSubProjectDataLoading,
  editClientContactStore,
  addClientContactStore,
  setEditClientDataState,
} = slice.actions;

export default slice.reducer;
