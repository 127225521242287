import { useDispatch, useSelector } from 'react-redux';
import { clientSubProjectFilterOptionsSelector, clientSubProjectFilterSelector } from '../../redux/selectors';
import { Button, DialogTitle, Drawer, FormControlLabel, Grid, IconButton, Switch, Typography } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { ReactComponent as FilterIcon } from '@assets/icons/filtersIcon.svg';
import { ReactComponent as ResetFiltersIcon } from '@assets/icons/resetFiltersIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './AllSubProjectFilter.styles';
import { LoadingButton } from '@mui/lab';
import { ClientSubProjectFilterOptions } from '@modules/EvaluationModule/interfaces/ClientSubProjectFilterOptions';
import { Formik, FormikProps } from 'formik';
import { noop } from 'lodash';
import { setClientSubProjectFilters } from '../../redux/actions';
import { ClientFiltersInitialValues } from '../constants/ClientContactFromInitialValues';
import { FiltersMultipleAutocomplete } from '../FiltersMultipleAutocomplete/FiltersMultipleAutocomplete';

interface AllFilterProps {
  noOfFilters: number;
}

export const AllSubProjectFilter = ({ noOfFilters }: AllFilterProps) => {
  const clientSubProjectFilterOptions = useSelector(clientSubProjectFilterOptionsSelector);
  const clientSubProjectFilter = useSelector(clientSubProjectFilterSelector);
  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<ClientSubProjectFilterOptions>>(null);
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleResetFilters = useCallback(() => {
    formRef.current?.setValues(ClientFiltersInitialValues);
    dispatch(setClientSubProjectFilters(ClientFiltersInitialValues));
  }, []);

  const handleChangeFilter = useCallback(
    (name: string, newValue: boolean, setFieldValue: (fieldName: string, fieldValue: boolean) => void) => {
      setFieldValue(name, newValue);
      dispatch(setClientSubProjectFilters({ [name]: newValue }));
    },
    []
  );

  const ResetBtn = (
    <Button startIcon={<ResetFiltersIcon />} onClick={handleResetFilters} size="small">
      Reset all
    </Button>
  );
  return (
    <>
      <div className={styles.btnsContainer}>
        <LoadingButton
          variant="contained"
          startIcon={<FilterIcon />}
          onClick={handleOpen}
          size="small"
          className={`${noOfFilters > 0 && styles.PurpleBackground}`}
        >
          Filters{noOfFilters > 0 && ` : ${noOfFilters}`}
        </LoadingButton>
        {noOfFilters > 0 && ResetBtn}
      </div>
      <Drawer anchor="right" open={open} onClose={handleClose} keepMounted>
        <DialogTitle className={styles.title}>Filters {noOfFilters > 0 && ResetBtn}</DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Formik initialValues={ClientFiltersInitialValues} innerRef={formRef} onSubmit={noop} validateOnMount>
          {({ setFieldValue }) => (
            <Grid container spacing={2} className={styles.drawlerRoot}>
              <Grid item xs={12}>
                <FiltersMultipleAutocomplete
                  name={'status'}
                  handleChange={setFieldValue}
                  options={clientSubProjectFilterOptions.status}
                  label={'Status'}
                  value={clientSubProjectFilter.status}
                  placeholder={clientSubProjectFilter.status.length > 0 ? '' : 'All statuses'}
                />
              </Grid>

              <Grid item xs={12}>
                <FiltersMultipleAutocomplete
                  name={'typeOfClient'}
                  handleChange={setFieldValue}
                  options={clientSubProjectFilterOptions.typeOfClient}
                  label={'Type of client'}
                  value={clientSubProjectFilter.typeOfClient}
                  placeholder={clientSubProjectFilter.typeOfClient.length > 0 ? '' : 'All types of client'}
                />
              </Grid>

              <Grid item xs={12}>
                <FiltersMultipleAutocomplete
                  name={'clientTitle'}
                  handleChange={setFieldValue}
                  options={clientSubProjectFilterOptions.clientTitle}
                  label={'Client title'}
                  value={clientSubProjectFilter.clientTitle}
                  placeholder={clientSubProjectFilter.clientTitle.length > 0 ? '' : 'All titles'}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={clientSubProjectFilter.includeClosedWorkstreams}
                      onChange={() =>
                        handleChangeFilter(
                          'includeClosedWorkstreams',
                          !clientSubProjectFilter.includeClosedWorkstreams,
                          setFieldValue
                        )
                      }
                    />
                  }
                  label={<Typography variant="body1">Show closed workstreams</Typography>}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">CSAT and evaluation</Typography>
              </Grid>
              <Grid item xs={12}>
                <FiltersMultipleAutocomplete
                  name={'feedbackGiver'}
                  handleChange={setFieldValue}
                  options={clientSubProjectFilterOptions.feedbackGiver}
                  label={'Feedback giver'}
                  value={clientSubProjectFilter.feedbackGiver}
                  placeholder={clientSubProjectFilter.feedbackGiver.length > 0 ? '' : 'Any status'}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Responsible Person</Typography>
              </Grid>

              <Grid item xs={12}>
                <FiltersMultipleAutocomplete
                  name={'contactOwner'}
                  handleChange={setFieldValue}
                  options={clientSubProjectFilterOptions.contactOwner}
                  label={'Contact owner'}
                  value={clientSubProjectFilter.contactOwner}
                  placeholder={clientSubProjectFilter.contactOwner.length > 0 ? '' : 'Anyone'}
                />
              </Grid>

              <Grid item xs={12}>
                <FiltersMultipleAutocomplete
                  name={'contactLocation'}
                  handleChange={setFieldValue}
                  options={clientSubProjectFilterOptions.contactLocation}
                  label={'Contact location'}
                  value={clientSubProjectFilter.contactLocation}
                  placeholder={clientSubProjectFilter.contactLocation.length > 0 ? '' : 'All Countries'}
                />
              </Grid>

              <Grid item xs={12}>
                <FiltersMultipleAutocomplete
                  name={'locationArea'}
                  handleChange={setFieldValue}
                  options={clientSubProjectFilterOptions.locationArea}
                  label={'Location area'}
                  value={clientSubProjectFilter.locationArea}
                  placeholder={clientSubProjectFilter.locationArea.length > 0 ? '' : 'All Areas'}
                />
              </Grid>
            </Grid>
          )}
        </Formik>
      </Drawer>
    </>
  );
};
