import { displayNotification } from '@modules/App/redux/notifications/actions';
import { patchRescheduleEval } from '@modules/EvaluationModule/api/evaluations';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { extendEval, rescheduleEval } from './actions';
import format from 'date-fns/format';
import { setExtendEvalState, setRescheduleEvalState } from '@modules/EvaluationModule/pages/MyTeamEvalPage/redux';
import { DataState } from '@shared/enums/DataState';
import { getMyTeamEvalData } from '@modules/EvaluationModule/pages/MyTeamEvalPage/redux/actions';
import { getUserEvalData } from '@modules/EvaluationModule/pages/UserEvalPage/redux/actions';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { updateEvalEopStatus } from '@modules/EvaluationModule/api/evalPageData';

function* extendEvalAsync({ payload }: ReturnType<typeof extendEval>) {
  try {
    const user: ReturnType<typeof userSelector> = yield select(userSelector);
    const userId = user?.id;
    yield put(setExtendEvalState(DataState.Pending));
    yield call(updateEvalEopStatus, payload.evaluationId, {
      eopStatus: payload.eopResult!,
      nextEvaluationDate: payload.newDate,
    });
    yield put(setExtendEvalState(DataState.Fulfilled));
    yield put(getMyTeamEvalData());
    if (userId) yield put(getUserEvalData(userId));
    yield put(displayNotification(`Evaluation rescheduled to ${format(new Date(payload.newDate), 'LLLL')} ⏳`));
  } catch {
    yield put(setExtendEvalState(DataState.Rejected));
    yield put(displayNotification('Failed to reschedule the evaluation'));
  }
}

function* rescheduleEvalAsync({ payload }: ReturnType<typeof rescheduleEval>) {
  try {
    const user: ReturnType<typeof userSelector> = yield select(userSelector);
    const userId = user?.id;
    yield put(setRescheduleEvalState(DataState.Pending));
    yield call(patchRescheduleEval, payload);
    yield put(setRescheduleEvalState(DataState.Fulfilled));
    yield put(getMyTeamEvalData());
    if (userId) yield put(getUserEvalData(userId));
    yield put(displayNotification(`Evaluation rescheduled to ${format(new Date(payload.newDate), 'LLLL')} ⏳`));
  } catch {
    yield put(setRescheduleEvalState(DataState.Rejected));
    yield put(displayNotification('Failed to reschedule the evaluation'));
  }
}

function* watchRescheduleEval() {
  yield takeEvery(rescheduleEval.type, rescheduleEvalAsync);
  yield takeEvery(extendEval.type, extendEvalAsync);
}

export function* evaluationsManegmentSaga(): Generator {
  yield all([watchRescheduleEval()]);
}
