import { ClientSubProjectFilterOptions } from '@modules/EvaluationModule/interfaces/ClientSubProjectFilterOptions';
import { ClietContactFormData } from '@modules/EvaluationModule/interfaces/ClietContactFormData';

export const ClientContactFromInitialValues: ClietContactFormData = {
  firstName: '',
  lastName: '',
  title: '',
  customTitle: '',
  workstream: '',
  emailAeTool: '',
  technicalPerson: false,
  location: '',
  locationArea: '',
  rmTypeOfContact: 'Client',
  doNotTouch: false,
  feedbackGiver: true,
  rmContactStatus: 'Current employee',
  csatGiver: false,
  csatEmailsEnabled: false,
  csatGiverLowFrequency: false,
  isAddingContact: true,
};

export const ClientFiltersInitialValues: ClientSubProjectFilterOptions = {
  status: [],
  typeOfClient: [],
  includeClosedWorkstreams: false,
  clientTitle: [],
  contactOwner: [],
  contactLocation: [],
  locationArea: [],
  feedbackGiver: [],
};
