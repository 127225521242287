import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',

    [BREAKPOINTS.MOBILE]: {
      minWidth: '800px',
    },
  },
  emptyView: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    fontWeight: '400',
    fontSize: 24,
    color: COLORS.TEXT.SECONDARY,
  },
  workstreamView: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    padding: 24,
    minHeight: '100vh',
    paddingBottom: 0,
    paddingInline: 0,
  },
  emailStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    cursor: 'pointer',
    color: COLORS.TEXT.DISABLED,
  },
  popup: {
    '&-content': {
      width: 'fit-content !important',
      padding: '4px 10px',
      position: 'fixed',
    },
  },
  contactEmail: {
    fontWeight: '400',
    fontSize: '14px',
    width: 300,
  },
  nonEmailBlock: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.STATUS.WARNING,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '& svg': {
      marginRight: 5,
    },
  },
  userInfoHolder: {
    width: 250,
  },
  loaderHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  emptyClients: {
    padding: '8px 0',
    color: COLORS.TEXT.SECONDARY,
  },
  nothingInZoho: {
    marginBottom: 32,
  },
  zohoWarning: {
    margin: '-24px -4px 16px',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    borderRadius: '0 0 4px 4px',
  },

  tableHeader: {
    '& .MuiTableCell-head': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '24px',
      color: COLORS.TEXT.SECONDARY,
    },
  },

  backgroundNeutral: {
    backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
  },

  subProjectName: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },

  noContactText: {
    color: COLORS.TEXT.DISABLED,
  },
  contactStatusText: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },

  scheduleButton: {
    color: COLORS.ACTION.PRIMARY,
    padding: 0,
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: COLORS.BRAND.PRIMARY,
      transform: 'scale(1.1)',
    },
  },

  editContactButton: {
    color: COLORS.ACTION.PRIMARY,
    padding: 0,
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: COLORS.BRAND.PRIMARY,
      transform: 'scale(1.1)',
    },
  },

  headerComponent: {
    paddingInline: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  subTitle: {
    color: COLORS.TEXT.SECONDARY,
    margin: 0,
    fontSize: '13px',
    lineHeight: '120%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '& svg': {
      width: 14,
      height: 14,
      fill: COLORS.ACTION.PRIMARY,
      cursor: 'pointer',

      '&:hover': {
        fill: COLORS.ACTION.DARK,
      },
    },
  },

  personName: {
    fontSize: '14px',
    margin: 0,
    textDecoration: 'none',
  },

  errorText: {
    color: COLORS.HIGHLIGHTS.DANGER,
  },

  disabledTextLight: {
    color: COLORS.TEXT.DISABLEDBG,
  },

  noContactForFilters: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80vh',
    fontWeight: '400',
    fontSize: 24,
    color: COLORS.TEXT.SECONDARY,
    '& button': {
      background: 'transparent !important',
      textDecoration: 'underline',
    },
  },

  btnsContainer: {
    display: 'flex',
    gap: 6,
  },

  contactLocation: {
    maxWidth: '180px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  subProjectTable: { maxHeight: '81.7vh' },
});
