import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './ManageClientModal.styles';
import { RockyButton } from '@shared/components/RockyButton';
import { RockyModal } from '@shared/components/RockyModal';
import { Field, Form, Formik, FormikErrors, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { validationSchema } from '../constants/validationSchema';
import isEmpty from 'lodash/isEmpty';
import { AddClietContactData, ClietContactFormData } from '@modules/EvaluationModule/interfaces/ClietContactFormData';
import { useModalState } from '@shared/hooks/useModalState';
import { addClientContact, editClientContact, setClientSubProjectNetworkError } from '../../redux/actions';
import classNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClientsPositions } from '@modules/EvaluationModule/enums/ClientsPositions';
import { ClientsTypeOfContacts } from '@modules/EvaluationModule/enums/ClientsTypeOfContact';
import { ClientRmContactStatus } from '@modules/EvaluationModule/enums/ClientRmContactStatus';
import { DataState } from '@shared/enums/DataState';
import { ClientSubProjectData } from '@modules/EvaluationModule/interfaces/ClientSubProjectData';
import { ClassNameMap } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { ClientContactFromInitialValues } from '../constants/ClientContactFromInitialValues';
import { ClientContactFormFields } from '@modules/EvaluationModule/pages/ClientContactPage/components/constants/ClientContactFormFields';
import { editClientDataStateSelector, networkErrorSelector } from '../../redux/selectors';
import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import { Stack, TableRow } from '@mui/material';

const EditClientButtonTrigger: React.FC<{ openModal: () => void; styles: ClassNameMap; children: ReactNode }> = ({
  openModal,
  children,
  styles,
}) => (
  <TableRow className={styles.contactRow} onClick={openModal}>
    {children}
  </TableRow>
);

const AddClientButtonTrigger: React.FC<{ openModal: () => void; styles: ClassNameMap }> = ({ openModal, styles }) => (
  <RockyButton cancel small className={styles.addBtn} onClick={openModal}>
    <AddIcon />
    Add contact
  </RockyButton>
);

interface Props {
  contact?: ClietContactFormData;
  choosenProject: ClientContactData;
  workstream?: ClientSubProjectData;
  children?: ReactNode;
  clientSubProjectData?: ClientSubProjectData[];
}

export const ManageClientModal: React.FC<Props> = ({
  contact,
  choosenProject,
  workstream,
  children,
  clientSubProjectData,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const networkError = useSelector(networkErrorSelector);
  const isEditMode = Boolean(contact);
  const editClientDataState = useSelector(editClientDataStateSelector);
  const formRef = useRef<FormikProps<ClietContactFormData>>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const { openModal, closeModal, isModalOpen } = useModalState();
  const [doNotDisturb, setDoNotDisturb] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState(false);
  const initialValues = contact
    ? { ...contact, workstream: contact.workstream || null }
    : ClientContactFromInitialValues;
  const choosenProjectName = choosenProject.name;

  useEffect(() => {
    setDoNotDisturb(contact?.doNotTouch ? true : false);
  }, [contact]);

  const executeModalScroll = () => {
    if (modalRef.current) {
      modalRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleClickSubmitIfValid = (
    validateForm: (values?: ClietContactFormData) => Promise<FormikErrors<ClietContactFormData>>
  ) => {
    if (!formRef.current) {
      return;
    }
    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        formRef.current?.handleSubmit();
      } else {
        setIsDirty(true);
        executeModalScroll();
      }
    });
  };

  useEffect(() => {
    if (editClientDataState === DataState.Fulfilled) {
      closeModal();
    }
  }, [editClientDataState]);

  const closeEditModal = useCallback(() => {
    closeModal();
    dispatch(setClientSubProjectNetworkError(null));
  }, []);

  const openClientModal = useCallback(() => {
    openModal();
  }, []);

  const handleSubmitEditClient = useCallback(
    (values: ClietContactFormData) => {
      if (contact && workstream) {
        dispatch(
          editClientContact({
            ...values,
            workstream: workstream.id,
            doNotTouch: doNotDisturb,
            id: contact.id,
            emailAeTool: values.emailAeTool.toLowerCase(),
          })
        );

        return;
      }
      const selectedWorkstream: ClientSubProjectData | undefined = clientSubProjectData?.filter(
        (client) => client.id === values.workstream
      )[0];
      const addClientContactData: AddClietContactData = {
        ...values,
        workstream: selectedWorkstream?.name === choosenProjectName ? null : values.workstream,
        doNotTouch: doNotDisturb,
        emailAeTool: values.emailAeTool.toLowerCase(),
        zohoCrmId: choosenProject.zohoCrmId,
      };
      dispatch(addClientContact(addClientContactData));
    },
    [doNotDisturb, choosenProject, workstream]
  );

  return (
    <>
      {isEditMode && children ? (
        <EditClientButtonTrigger openModal={openClientModal} styles={styles} children={children} />
      ) : (
        <AddClientButtonTrigger openModal={openClientModal} styles={styles} />
      )}
      <RockyModal
        title="Client point of contact"
        isOpen={isModalOpen}
        onClose={closeModal}
        crossIcon
        modalBodyStyles={styles.modalBodyStyles}
        ref={modalRef}
        backdropClose={false}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues!}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={handleSubmitEditClient}
        >
          {({ validateForm, values, errors, setFieldValue }: FormikProps<ClietContactFormData>) => (
            <Form>
              <div className={styles.nameContainer}>
                <div className={styles.fieldContainer}>
                  <div>
                    <span className={styles.fieldTitle}>{ClientContactFormFields.firstName.label}</span>
                    {isDirty && errors.firstName && <span className={styles.fieldError}> {errors.firstName}</span>}
                  </div>
                  <Field name={ClientContactFormFields.firstName.name} className={styles.fieldsText} />
                </div>
                <div className={styles.fieldContainer}>
                  <div>
                    <span className={styles.fieldTitle}>{ClientContactFormFields.lastName.label}</span>
                    {isDirty && errors.lastName && <span className={styles.fieldError}> {errors.lastName}</span>}
                  </div>
                  <Field name={ClientContactFormFields.lastName.name} className={styles.fieldsText} />
                </div>
              </div>
              <div className={styles.fieldContainer}>
                <div>
                  <span className={styles.fieldTitle}>{ClientContactFormFields.title.label}</span>
                  {isDirty && errors.title && <span className={styles.fieldError}> {errors.title}</span>}
                </div>
                <div className={styles.selectContainer}>
                  <Field
                    name={ClientContactFormFields.title.name}
                    as="select"
                    className={classNames(styles.fieldsText, styles.select)}
                  >
                    <option style={{ display: 'none' }} value={''}>
                      -- Select title --
                    </option>
                    {Object.values(ClientsPositions).map((position, i) => (
                      <option key={i} value={position}>
                        {position}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </Field>
                  <ExpandMoreIcon className={styles.arrow} />
                </div>
                {values.title === 'Other' && (
                  <div className={styles.fieldContainer}>
                    <div>
                      <span className={styles.fieldTitle}>{ClientContactFormFields.customTitle.label}</span>
                      {isDirty && errors.customTitle && (
                        <span className={styles.fieldError}> {errors.customTitle}</span>
                      )}
                    </div>
                    <Field name={ClientContactFormFields.customTitle.name} className={styles.fieldsText} />
                  </div>
                )}
                <label className={styles.checkboxLabel}>
                  <Field
                    type="checkbox"
                    name={ClientContactFormFields.technicalPerson.name}
                    className={styles.checkboxField}
                  />
                  <span>{ClientContactFormFields.technicalPerson.label} </span>
                </label>
                {!isEditMode && clientSubProjectData && (
                  <>
                    <div>
                      <span className={styles.fieldTitle}>{ClientContactFormFields.workstream.label}</span>
                      {isDirty && errors.workstream && <span className={styles.fieldError}> {errors.workstream}</span>}
                    </div>
                    <div className={styles.selectContainer}>
                      <Field
                        name={ClientContactFormFields.workstream.name}
                        as="select"
                        className={classNames(styles.fieldsText, styles.select)}
                      >
                        <option style={{ display: 'none' }} value={''}>
                          -- Select workstream --
                        </option>
                        {clientSubProjectData.map((workstream, i) => (
                          <option key={i} value={workstream.id ?? ''}>
                            {workstream.name}
                          </option>
                        ))}
                      </Field>
                      <ExpandMoreIcon className={styles.arrow} />
                    </div>
                  </>
                )}
                <div className={styles.fieldContainer}>
                  <div>
                    <span className={styles.fieldTitle}>{ClientContactFormFields.emailAeTool.label}</span>
                    {isDirty && errors.emailAeTool && <span className={styles.fieldError}> {errors.emailAeTool}</span>}
                  </div>
                  <Field name={ClientContactFormFields.emailAeTool.name} type="email" className={styles.fieldsText} />
                </div>
                <div className={styles.fieldContainer}>
                  <span className={styles.fieldTitle}>{ClientContactFormFields.rmTypeOfContact.label}</span>
                  <div className={styles.selectContainer}>
                    <Field
                      name={ClientContactFormFields.rmTypeOfContact.name}
                      as="select"
                      className={classNames(styles.fieldsText, styles.select)}
                    >
                      {Object.values(ClientsTypeOfContacts).map((contactType, i) => (
                        <option value={contactType} key={i}>
                          {contactType}
                        </option>
                      ))}
                    </Field>
                    <ExpandMoreIcon className={styles.arrow} />
                  </div>
                </div>
                <div className={styles.fieldContainer}>
                  <span className={styles.fieldTitle}>{ClientContactFormFields.rmContactStatus.label}</span>
                  <div className={styles.selectContainer}>
                    <Field
                      name={ClientContactFormFields.rmContactStatus.name}
                      as="select"
                      className={classNames(styles.fieldsText, styles.select)}
                    >
                      {Object.values(ClientRmContactStatus).map((contactType, i) => (
                        <option value={contactType} key={i}>
                          {contactType}
                        </option>
                      ))}
                    </Field>
                    <ExpandMoreIcon className={styles.arrow} />
                  </div>
                </div>
                <div className={styles.fieldContainer}>
                  <>
                    <span className={styles.fieldTitle}>{ClientContactFormFields.location.label}</span>
                    {isDirty && errors.location && <span className={styles.fieldError}> {errors.location}</span>}
                  </>
                  <Field
                    name={ClientContactFormFields.location.name}
                    value={values.location || ''}
                    className={styles.fieldsText}
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <div>
                    <span className={styles.fieldTitle}>{ClientContactFormFields.locationArea.label}</span>
                    {isDirty && errors.locationArea && (
                      <span className={styles.fieldError}> {errors.locationArea}</span>
                    )}
                  </div>
                  <Field name={ClientContactFormFields.locationArea.name} className={styles.fieldsText} />
                </div>
                {![ClientRmContactStatus.formerEmployee, ClientRmContactStatus.formerPoc].includes(
                  contact?.rmContactStatus as ClientRmContactStatus
                ) && (
                  <Stack
                    rowGap="30px"
                    sx={{
                      marginTop: '25px',
                    }}
                  >
                    <div>
                      <div>
                        <label className={styles.checkboxLabel}>
                          <Field
                            name={ClientContactFormFields.csatGiver.name}
                            type="checkbox"
                            className={styles.checkboxField}
                          />
                          {ClientContactFormFields.csatGiver.label}
                        </label>
                      </div>
                      {values.csatGiver && (
                        <>
                          <div className={styles.emailNotificationContainer}>
                            <label>
                              <Field
                                type="radio"
                                name={ClientContactFormFields.csatGiverLowFrequency.name}
                                className={styles.emailRadio}
                                checked={!values.csatGiverLowFrequency}
                                onChange={() => {
                                  setFieldValue(ClientContactFormFields.csatGiverLowFrequency.name, false);
                                }}
                              />
                              Monthly
                            </label>
                            <label>
                              <Field
                                type="radio"
                                name={ClientContactFormFields.csatGiverLowFrequency.name}
                                className={styles.emailRadio}
                                checked={values.csatGiverLowFrequency}
                                onChange={() => {
                                  setFieldValue(ClientContactFormFields.csatGiverLowFrequency.name, true);
                                }}
                              />
                              Quarterly
                            </label>
                          </div>
                          <div className={styles.emailNotificationContainer}>
                            <label>
                              <Field
                                type="radio"
                                name={ClientContactFormFields.csatEmailsEnabled.name}
                                className={styles.emailRadio}
                                checked={values.csatEmailsEnabled}
                                onChange={() => {
                                  setFieldValue(ClientContactFormFields.csatEmailsEnabled.name, true);
                                }}
                              />
                              Send automated emails
                            </label>
                            <label>
                              <Field
                                type="radio"
                                name={ClientContactFormFields.csatEmailsEnabled.name}
                                className={styles.emailRadio}
                                checked={!values.csatEmailsEnabled}
                                onChange={() => {
                                  setFieldValue(ClientContactFormFields.csatEmailsEnabled.name, false);
                                }}
                              />
                              Send link manually
                            </label>
                          </div>
                          <div className={styles.disclaimer}>
                            {values.csatEmailsEnabled
                              ? 'Client will receive monthly survey email with a follow-up after 7 days if needed'
                              : 'Unique link will be generated for you to reach out to the client'}
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <div>
                        <label className={styles.checkboxLabel}>
                          <Field
                            name={ClientContactFormFields.feedbackGiver.name}
                            type="checkbox"
                            className={styles.checkboxField}
                          />
                          {ClientContactFormFields.feedbackGiver.label}
                        </label>
                      </div>
                      <div className={styles.emailNotificationContainer}>
                        <label>
                          <Field
                            type="radio"
                            name={ClientContactFormFields.doNotTouch.name}
                            value={doNotDisturb}
                            checked={!doNotDisturb}
                            onChange={() => {
                              setDoNotDisturb(false);
                            }}
                            className={styles.emailRadio}
                          />
                          {ClientContactFormFields.doNotTouch.label}
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name={ClientContactFormFields.doNotTouch.name}
                            value={doNotDisturb}
                            checked={doNotDisturb}
                            onChange={() => {
                              setDoNotDisturb(true);
                            }}
                            className={styles.emailRadio}
                          />
                          Send link manually
                        </label>
                      </div>
                      {isDirty && doNotDisturb === null && <span className={styles.fieldError}> please choose</span>}
                      <div className={styles.disclaimer}>
                        {!doNotDisturb
                          ? 'Client will receive an email when evaluation starts and will be reminded 3 times each Monday before the evaluation'
                          : 'Unique link will be generated for you to reach out to the client'}
                      </div>
                    </div>
                  </Stack>
                )}
              </div>
              {networkError && <div className={styles.error}>{networkError}</div>}
              <div className={styles.btnContainer}>
                <RockyButton onClick={closeEditModal}>Cancel</RockyButton>
                {editClientDataState === DataState.Pending ? (
                  <RockyButton action disabled>
                    <div className={styles.spinner}></div>
                  </RockyButton>
                ) : (
                  <RockyButton action onClick={() => handleClickSubmitIfValid(validateForm)}>
                    {isEditMode ? 'Save' : 'Add'}
                  </RockyButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </RockyModal>
    </>
  );
};
