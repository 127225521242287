import { RootState } from '@modules/App/redux/store';
import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import { ClientSubProjectData } from '@modules/EvaluationModule/interfaces/ClientSubProjectData';
import { ClientSubProjectFilterOptions } from '@modules/EvaluationModule/interfaces/ClientSubProjectFilterOptions';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';

export const networkErrorSelector = (state: RootState): string | null =>
  state.evaluation.clientContactPage.clientSubProjectNetworkError;

export const editClientDataStateSelector = (state: RootState): DataState =>
  state.evaluation.clientContactPage.editClientDataState;

export const clientContactDataSelector = (state: RootState): AsyncData<ClientContactData[]> =>
  state.evaluation.clientContactPage.clientContactData;

export const clientSubProjectDataSelector = (state: RootState): AsyncData<ClientSubProjectData[]> =>
  state.evaluation.clientContactPage.clientSubProjectData;

export const clientSubProjectFilterOptionsSelector = (state: RootState): ClientSubProjectFilterOptions =>
  state.evaluation.clientContactPage.uniqueFilterOptions;

export const clientSubProjectFilterSelector = (state: RootState): ClientSubProjectFilterOptions =>
  state.evaluation.clientContactPage.clientFilters;
