import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import { ClientSubProjectData } from '@modules/EvaluationModule/interfaces/ClientSubProjectData';
import { ClientSubProjectFilterOptions } from '@modules/EvaluationModule/interfaces/ClientSubProjectFilterOptions';
import { AddClietContactData, ClietContactFormData } from '@modules/EvaluationModule/interfaces/ClietContactFormData';
import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from '@shared/interfaces/asyncData';

export const getClientContactData = createAction('clientContactPage/getClientContactData');
export const setClientContactData = createAction<AsyncData<ClientContactData[]>>(
  'clientContactPage/setClientContactData'
);
export const getClientSubProjectData = createAction<{ name: string | null; id: string | null }>(
  'clientContactPage/getClientSubProjectData'
);
export const setClientSubProjectData = createAction<AsyncData<ClientSubProjectData[]>>(
  'clientContactPage/setClientSubProjectData'
);
export const addClientContact = createAction<AddClietContactData>('clientContactPage/addClientContact');
export const editClientContact = createAction<ClietContactFormData>('clientContactPage/editClientContact');

export const setClientSubProjectNetworkError = createAction<string | null>(
  'clientContactPage/setClientSubProjectNetworkError'
);

export const setClientSubProjectFilters = createAction<Partial<ClientSubProjectFilterOptions>>(
  'clientContactPage/setClientSubProjectFilters'
);
