export enum WorkstreamStatus {
  Closed = 'Closed',
  NotClosed = 'Not closed',
}

export enum FeedbackGiver {
  CsatGiver = 'CSAT givers',
  NotCsatGiver = "Don't give CSAT",
  FeedbackGiver = 'Evaluation feedback givers',
  NotFeedbackGiver = "Don't give eval feedback",
}
