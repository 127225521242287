import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  btnsContainer: {
    display: 'flex',
    gap: 6,
  },
  title: {
    display: 'flex',
    gap: 12,
  },
  drawlerRoot: {
    width: 465,
    padding: 32,
    paddingTop: 5,
  },
  PurpleBackground: {
    backgroundColor: COLORS.BRAND.ACCENT,
    '&:hover': {
      backgroundColor: '#651a72',
    },
  },
});
