import React, { useCallback, useMemo } from 'react';
import { useStyles } from './SubProjectTable.styles';
import { ReactComponent as EmptyViewIcon } from '@assets/icons/EmptyContactIcon.svg';
import { useClientSubProjectData } from '../../hooks/useClientSubProjectData';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { RocketLoader } from '@shared/components/RocketLoader';
import { ManageClientModal } from '@modules/EvaluationModule/pages/ClientContactPage/components/ManageClientModal/ManageClientModal';
import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import { AllSubProjectFilter } from '../AllSubProjectFilter/AllSubProjectFilter';
import { useDispatch, useSelector } from 'react-redux';
import { clientSubProjectFilterSelector } from '../../redux/selectors';
import { filterClientContacts } from '../../helper/filterClientContacts';
import { ClientFiltersInitialValues } from '../constants/ClientContactFromInitialValues';
import { setClientSubProjectFilters } from '../../redux/actions';

import { SubProjectTableRow } from './SubProjectTableRow';

interface Props {
  choosenProject: ClientContactData;
}

export const SubProjectTable: React.FC<Props> = ({ choosenProject }) => {
  const styles = useStyles();
  const clientSubProjectData = useClientSubProjectData(choosenProject);
  const isRejected = clientSubProjectData.state === DataState.Rejected;
  const isDataLoading = clientSubProjectData.state === DataState.Pending;
  const clientSubProjectFilter = useSelector(clientSubProjectFilterSelector);
  const dispatch = useDispatch();
  const { filteredClientSubProjectData, numberOfFilters, noContactsAvailable } = useMemo(() => {
    return filterClientContacts(clientSubProjectData.data ?? [], clientSubProjectFilter);
  }, [clientSubProjectFilter, clientSubProjectData.data]);

  const handleResetFilters = useCallback(() => {
    dispatch(setClientSubProjectFilters(ClientFiltersInitialValues));
  }, []);

  const includeClosedWorkstreams = useCallback(() => {
    dispatch(setClientSubProjectFilters({ includeClosedWorkstreams: true }));
  }, []);

  const NoContactsAvailable = numberOfFilters ? (
    <div className={styles.noContactForFilters}>
      <Typography variant="h2">
        No client contacts matching {`${numberOfFilters == 1 ? 'this filter' : 'these filters'}`}
      </Typography>
      <Button variant="text" onClick={handleResetFilters}>
        reset filters
      </Button>
    </div>
  ) : (
    <div className={styles.noContactForFilters}>
      <Typography variant="h2">No client contacts or active workstreams</Typography>
      <Button variant="text" onClick={includeClosedWorkstreams}>
        show closed workstreams
      </Button>
    </div>
  );

  return isRejected ? (
    <DataErrorView />
  ) : (
    <div className={styles.root}>
      {choosenProject.name === null || choosenProject.id === null ? (
        <div className={styles.emptyView}>
          <EmptyViewIcon />
          Select client to edit contacts
        </div>
      ) : isDataLoading ? (
        <div className={styles.loaderHolder}>
          <RocketLoader />
        </div>
      ) : (
        <div className={styles.workstreamView}>
          <Alert severity="warning" icon={false} className={styles.zohoWarning}>
            <Stack flexDirection="row" columnGap="10px" alignItems="center">
              <PermContactCalendarOutlinedIcon color="disabled" />
              <Typography variant="body2">
                Recently created client contacts take 1-2 minutes to be loaded from ZohoCRM
              </Typography>
            </Stack>
          </Alert>
          <div className={styles.headerComponent}>
            <Typography variant="h1" mb={4}>
              {choosenProject.name}
              <ManageClientModal
                choosenProject={choosenProject}
                clientSubProjectData={clientSubProjectData.data ?? []}
              />
            </Typography>
            <AllSubProjectFilter noOfFilters={numberOfFilters} />
          </div>
          {noContactsAvailable ? (
            NoContactsAvailable
          ) : (
            <TableContainer className={styles.subProjectTable}>
              <Table stickyHeader aria-label="table">
                <TableHead>
                  <TableRow className={styles.tableHeader}>
                    <TableCell align="left">Point of contact</TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Location</TableCell>
                    <TableCell align="left">Feedbacks</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                {filteredClientSubProjectData &&
                  filteredClientSubProjectData.map((workstream) => {
                    return (
                      <TableBody key={workstream.name}>
                        <SubProjectTableRow
                          choosenProject={choosenProject}
                          key={workstream.id}
                          workstream={workstream}
                        />
                      </TableBody>
                    );
                  })}
              </Table>
            </TableContainer>
          )}
        </div>
      )}
    </div>
  );
};
