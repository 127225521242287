import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useStyles } from './SubProjectTable.styles';
import { ClientSubProjectData } from '@modules/EvaluationModule/interfaces/ClientSubProjectData';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import StarIcon from '@mui/icons-material/Star';
import CopyLinkIcon from '@mui/icons-material/Link';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CreateIcon from '@mui/icons-material/Create';
import classNames from 'classnames';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { copyToClipBoard } from '@shared/helpers/copyToClipBoard';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { ClientRmContactStatus } from '@modules/EvaluationModule/enums/ClientRmContactStatus';
import { ManageClientModal } from '../ManageClientModal/ManageClientModal';
import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import { getGoogleCalendarMeetingLink } from '@modules/HappinessModule/helpers/googleCalendar';
import { useAuth } from '@shared/hooks/useAuth';
import { WorkstreamStatus } from '../../enums/ContactFilterOptions';

interface Props {
  workstream: ClientSubProjectData;
  choosenProject: ClientContactData;
}
export const SubProjectTableRow = ({ workstream, choosenProject }: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const noName = 'No name';

  const handleCopyLink = useCallback((e, email) => {
    copyToClipBoard(e)(email, () => {
      dispatch(displayNotification("Contact's email was copied to clipboard"));
    });
  }, []);

  const scheduleMeeting = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, email: string, name: string) => {
      e.stopPropagation();
      window.open(
        getGoogleCalendarMeetingLink({
          inviteeName: currentUser?.name || '',
          inviteeEmail: email.toLowerCase(),
          inviterName: name,
        })
      );
    },
    []
  );

  const sendMail = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, email: string) => {
    e.stopPropagation();
    window.open(`mailto:${email.toLowerCase()}`);
  }, []);

  return (
    <>
      <TableRow className="no-height">
        <TableCell colSpan={7} className={styles.backgroundNeutral}>
          <span className={styles.subProjectName}>
            {workstream.status === WorkstreamStatus.Closed ? (
              <WorkOffOutlinedIcon />
            ) : workstream.contacts.length ? (
              <FolderSharedOutlinedIcon />
            ) : (
              <FolderOutlinedIcon />
            )}

            {workstream.name}
            {workstream.contacts.length === 0 && <span className={styles.noContactText}> No contacts</span>}
          </span>
        </TableCell>
      </TableRow>

      {!workstream?.id && (
        <TableCell colSpan={7}>
          <div className={classNames(styles.emptyClients, styles.nothingInZoho)}>
            There is no information associated with {workstream.name}.<br />
            Please contact Zoho CRM admins.
          </div>
        </TableCell>
      )}

      {workstream?.id &&
        workstream.contacts.map((contact) => {
          const contactName: string =
            contact.firstName === noName && contact.lastName === noName
              ? noName
              : contact.firstName === noName && contact.lastName !== noName
              ? contact.lastName
              : contact.firstName !== noName && contact.lastName === noName
              ? contact.firstName
              : `${contact.firstName} ${contact.lastName}`;
          return (
            <ManageClientModal
              contact={contact}
              choosenProject={choosenProject}
              workstream={workstream}
              key={contact.id}
            >
              <TableCell>
                <p className={styles.personName} id="name">
                  {contactName}
                </p>
                <p className={classNames(styles.subTitle, `${!contact.emailAeTool && styles.errorText}`)}>
                  {contact.emailAeTool.toLowerCase() || 'No email available'}
                  {contact.emailAeTool && (
                    <IconButton onClick={(e) => handleCopyLink(e, contact.emailAeTool)}>
                      <ContentCopyRoundedIcon />
                    </IconButton>
                  )}
                </p>
              </TableCell>
              <TableCell>{contact.title}</TableCell>
              <TableCell>
                <span
                  className={classNames(
                    styles.contactStatusText,
                    `${contact.rmContactStatus === ClientRmContactStatus.formerPoc && styles.noContactText}`
                  )}
                >
                  {!contact.rmContactStatus && <HorizontalRuleIcon className={styles.noContactText} />}
                  {contact.rmContactStatus === ClientRmContactStatus.mainCurrentPoc && <StarIcon />}
                  {contact.rmContactStatus && contact.rmContactStatus}
                </span>
              </TableCell>

              <TableCell className={styles.contactLocation}>{contact.location}</TableCell>

              <TableCell>
                <div className={styles.subProjectName}>
                  {contact.feedbackGiver ? (
                    <Tooltip
                      title={contact.doNotTouch ? 'Sending link manually' : 'Eval feedback giver'}
                      placement="top"
                    >
                      <div className={styles.emailStatusContainer}>
                        {contact.doNotTouch ? <CopyLinkIcon /> : <WorkspacePremiumOutlinedIcon />}
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={classNames(styles.emailStatusContainer, styles.disabledTextLight)}>
                      <WorkspacePremiumOutlinedIcon />
                    </div>
                  )}

                  {contact.csatGiver ? (
                    <Tooltip
                      title={!contact.csatEmailsEnabled ? 'Sending link manually' : 'CSAT giver'}
                      placement="top"
                    >
                      <div className={styles.emailStatusContainer}>
                        <ContactMailOutlinedIcon />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={classNames(styles.emailStatusContainer, styles.disabledTextLight)}>
                      <ContactMailOutlinedIcon />
                    </div>
                  )}
                </div>
              </TableCell>

              <TableCell>
                <span className={styles.subProjectName}>
                  <Tooltip title="Schedule a call" placement="top">
                    <IconButton
                      className={styles.scheduleButton}
                      onClick={(e) => scheduleMeeting(e, contact.emailAeTool, contactName)}
                    >
                      <VideoCallOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send email" placement="top">
                    <IconButton
                      className={styles.scheduleButton}
                      onClick={(e) => sendMail(e, contact.emailAeTool.toLowerCase())}
                    >
                      <EmailOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit contact" placement="top">
                    <IconButton className={styles.editContactButton}>
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </span>
              </TableCell>
            </ManageClientModal>
          );
        })}
    </>
  );
};
