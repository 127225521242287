import { FC, useEffect } from 'react';
import { useStyles } from './CreateFlagFormPage.styles';
import { useUserData } from '@modules/App/pages/UserPage/hooks/useUserData';
import { BackLink } from '@shared/components/BackLink';
import { DataState } from '@shared/enums/DataState';
import { RocketLoader } from '@shared/components/RocketLoader';
import { getInitValues } from './helpers/initialValues';
import { FlagForm } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/FlagForm';
import { useUserFlagCcDetails } from './hooks/useUserFlagCcDetails';
import { useAuth } from '@shared/hooks/useAuth';
import { useUserPermissions } from '@shared/hooks/usePermissions';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { DataErrorView } from '@shared/components/DataErrorView';
import { PageState } from '@shared/enums/PageState';
import { ccEmailsGenerate } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/helpers/ccEmailsGenerate';
import { flagBackLinkPathByUserIdCreatePage } from './helpers/flagBackLinkPathByUserIdCreatePage';
import { useToms } from '@shared/hooks/useToms';
import { usePrs } from '@shared/hooks/usePrs';
import { mapUserToSharedUser } from '@shared/helpers/mapUserToSharedUser';
import { FlagWatcherData } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { FLAG_WATCHERS_READ_UPDATE_PERMISSIONS } from '../../constants/constants';
import { useUserAutomaticFlagWatchers } from '../../hooks/useUserAutomaticFlagWatchers';
import { getAutomaticFlagWatchers, getCcFlagWatchers } from '../../helpers/getAllAutomaticFlagWatchers';
import { FlagWatcherRole } from '../../enums/flagWatcherRole';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { DUPLICATE_FLAG_ID_QUERY_PARAM } from '@modules/HappinessModule/constants/queryParamsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getFlagDataAction } from '../EditFlagFormPage/redux/actions';
import { flagDataSelector } from '../EditFlagFormPage/redux/selectors';
import { setFlagData } from '../EditFlagFormPage/redux/reducers';

export const CreateFlagFormPage: FC = () => {
  const styles = useStyles();
  const { userData, userId } = useUserData();
  const { currentUser } = useAuth();
  const userPermissoins = useUserPermissions({ id: userId });
  useToms({
    isNeedToBeFetched:
      userPermissoins.data?.flagPermissions.newHomeTomSection.isCreatable ||
      userPermissoins.data?.flagPermissions.newHomeTomSection.isUpdatable,
  });
  usePrs();
  const userFlagCcDetails = useUserFlagCcDetails();
  const userFirstName = userData.data?.name.split(' ')[0];
  const isCurrentUserHasPermissionToCreateFlag = userPermissoins.data?.flagPermissions.flag.isCreatable;
  const isLoadingUserPermissions = userPermissoins.state === DataState.Pending;
  const isUserDataLoading = userData.state === DataState.Pending;
  const isLoadingUserFlagCcDetails = userFlagCcDetails.state === DataState.Pending;
  const pageState = isLoadingUserPermissions
    ? null
    : isCurrentUserHasPermissionToCreateFlag
    ? PageState.CreateView
    : PageState.NoAccessView;

  const location = useLocation();
  const duplicateFlagId = get(location.state, DUPLICATE_FLAG_ID_QUERY_PARAM) as string | null;
  const duplictedFlag = useSelector(flagDataSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!duplicateFlagId) return;
    dispatch(getFlagDataAction(duplicateFlagId));
  }, [duplicateFlagId]);

  useEffect(() => {
    return () => {
      dispatch(setFlagData({ data: null, state: DataState.Pending }));
    };
  }, []);

  const isPageLoading =
    isUserDataLoading ||
    !pageState ||
    isLoadingUserFlagCcDetails ||
    isLoadingUserPermissions ||
    (duplicateFlagId && duplictedFlag.state === DataState.Pending);

  const flagAuthor = currentUser ? mapUserToSharedUser(currentUser) : null;
  const { data: automaticFlagWatchersFromBE, state: automaticFlagWatchersFromBEDataState } =
    useUserAutomaticFlagWatchers({
      userId: userId ?? null,
    });
  const flagFor = userData.data ?? null;
  const flagForId = flagFor?.id ?? null;
  const reportsTo = flagFor?.primaryReportsTo ?? null;
  const flagAuthorIsReportsTo = reportsTo?.id === flagAuthor?.id;

  const manuallyAddedFlagWatchers = flagForId
    ? ([
        !flagAuthorIsReportsTo && reportsTo
          ? {
              user: reportsTo,
              flagWatcherRole: FlagWatcherRole.ReportingTo,
              canBeRemoved: true,
              permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
            }
          : null,
        ...getCcFlagWatchers({
          userFlagCcDetails: userFlagCcDetails.data,
          flagForId,
        }),
      ].filter((item) => item !== null) as FlagWatcherData[])
    : [];

  const userAutomaticFlagWatchers =
    automaticFlagWatchersFromBE &&
    flagAuthor &&
    userFlagCcDetails.data &&
    flagForId &&
    getAutomaticFlagWatchers({
      automaticFlagWatchersFromBE,
      userFlagCcDetails: userFlagCcDetails.data,
      reportsTo,
      flagAuthor,
      manuallyAddedFlagWatchers: manuallyAddedFlagWatchers,
      flagForId,
    });

  const ccRecipients =
    flagFor && flagAuthor && userFlagCcDetails.data && userAutomaticFlagWatchers
      ? ccEmailsGenerate({
          userFlagCcDetails: userFlagCcDetails.data,
          reportsTo,
          flagAuthor,
          flagFor,
          userAutomaticFlagWatchers,
        })
      : [];

  const initialValues = getInitValues({
    ccRecipients,
    flagWatchers: manuallyAddedFlagWatchers,
    duplicatedFlagFields: duplictedFlag.data?.flagFields,
  });

  const isLoadingDataFailed =
    userData.state === DataState.Rejected ||
    userFlagCcDetails.state === DataState.Rejected ||
    userPermissoins.state === DataState.Rejected ||
    automaticFlagWatchersFromBEDataState === DataState.Rejected;

  return (
    <div>
      {!isLoadingDataFailed && (
        <BackLink
          name={isUserDataLoading ? '...' : `${userFirstName}'s profile`}
          path={flagBackLinkPathByUserIdCreatePage(flagFor?.id ?? '')}
          classNameHolder={styles.backLink}
        />
      )}
      <div className={styles.root}>
        {pageState === PageState.NoAccessView ? (
          <AccessDeniedView />
        ) : isLoadingDataFailed ? (
          <DataErrorView error={userData.error} />
        ) : isPageLoading ? (
          <div className={styles.loaderHolder}>
            <RocketLoader />
          </div>
        ) : (
          flagFor &&
          flagAuthor &&
          userPermissoins.data?.flagPermissions &&
          userAutomaticFlagWatchers && (
            <FlagForm
              userFlagPermissions={userPermissoins.data.flagPermissions}
              flagData={{
                id: '1',
                user: {
                  ...mapUserToSharedUser(flagFor),
                  zohoRecruitId: flagFor.zohoRecruitId,
                  engineeringManager: userFlagCcDetails.data?.engineeringManager,
                },
                author: flagAuthor,
                flagFields: initialValues,
                createdAt: new Date().toISOString(),
                resolvedAt: null,
              }}
              flagFormState={pageState}
              userAutomaticFlagWatchers={userAutomaticFlagWatchers}
            />
          )
        )}
      </div>
    </div>
  );
};
